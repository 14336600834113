import React, { useState } from 'react';
import styled from 'styled-components';
import { run } from 'tripetto-runner-classic/runner/es5';
import TripettoServices from 'tripetto-services/dist/es5';
import Modal from 'react-modal';
import cross from '../components/icons/cross-green.svg';
import { red, yellow, desktop, tablet } from '../constants';

Modal.setAppElement('#___gatsby');

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '550px',
    maxWidth: '80vw',
    minHeight: '350px',
    zIndex: 302
  },
  overlay: {
    position: 'fixed',
    zIndex: 302
  },
};

const ContactButton = styled.button`
  background-color: ${({ dark }) => dark ? yellow : red};
  border: none;
  color: ${({ dark }) => dark ? red : 'white'};;
  padding: 1rem;
  display: block;
  text-align: center;
  text-decoration: none;
  font-size: 1.25rem;
  margin: ${({ dark }) => dark ? '2rem 0 0' : '0 auto 3rem'};
  cursor: pointer;
  transition: all 0.1s;

  &:hover, &:focus {
    ${({ nav }) => nav ? `
      text-decoration: underline;
    ` : `
      background-color: ${yellow};
      color: ${red};
    `}
  }

  ${({ nav }) => nav && `
    background-color: transparent;
    color: #f1dbc7;
    margin: 0;
    padding: 0;
    font-size: 3rem;
    font-weight: 800;
    line-height: 1;
    font-family: 'Manrope',Helvetica,Arial,Sans-Serif;
    overflow: visible;
    text-align: left;

    @media (min-width: ${tablet}) {
      font-size: 3.5rem;
      line-height: 1.25;
    }
  `}

    @media (min-width: ${desktop}) {
      font-size: ${({ nav }) => nav ? 4 : 1.5}rem;
      line-height: 1.25;
    }
`;

const CrossIcon = styled.img`
  width: 1.5rem;
`;

const CloseButton = styled.button`
  background-color: transparent;
  padding: 0;
  margin: 0 0 1rem;
  border-width: 0;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  cursor: pointer;
  z-index: 301;

  @media (min-width: ${desktop}) {
    left: 0.75rem;
    top: 1.75rem;
  }
`;

const ContactForm = ({ dark, nav }) => {

  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    const { definition, styles, l10n, locale, translations, attachments, onSubmit } = TripettoServices.init({ token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoiRk9PZUZHWXFLUW9YVjhJVTQ4cDhsWXZWdFpIQnJ6d3dCcnVKTWR5M0lGST0iLCJkZWZpbml0aW9uIjoiYTlYNTg2Z0lPb1RnbVltQzhrUlA5NGhlUFpSYWFPSVdjd3luaktBTVdLUT0iLCJ0eXBlIjoiY29sbGVjdCJ9.HZ7umblnLrOYEyS--7F9LOsguDIDcw0Cwy4dL9zivy4" });

    run({
      element: document.getElementById("tripetto"),
      definition,
      styles,
      l10n,
      locale,
      translations,
      attachments,
      onSubmit
  });
  }

  function closeModal() {
    setIsOpen(false);
  }

  return <>
    <ContactButton dark={dark} nav={nav} onClick={openModal}>Contact Us</ContactButton>
    <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        contentLabel="Contact Form"
        style={modalStyles}
      >
        <CloseButton type="button" onClick={closeModal}><CrossIcon src={cross} alt="Close" /></CloseButton>
        <div id="tripetto" />
      </Modal>
  </>
};

export default ContactForm;