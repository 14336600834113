import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import A11yModeContext from '../contexts/A11yModeContext';
import logo from './icons/logo-green.svg';
import burger from './icons/burger.svg';
import cross from './icons/cross.svg';
import { desktop, tablet, red, pink, green } from '../constants';
import onClickOutside from 'react-onclickoutside';
import { useStaticQuery, graphql } from 'gatsby';
import ContactForm from './ContactForm';

const HeaderComponent = styled.header`
  padding: 2.25rem 1.25rem;
  height: 7rem;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-weight: 800;
  display: flex;
  flex-direction: column;

  @media (min-width: ${desktop}) {
    height: 18rem;
  }
`;

const HeaderLink = styled(Link)`
  text-decoration: none;
`;

const Logo = styled.img`
  width: 10rem;

  @media (min-width: ${desktop}) {
    width: 22rem;
  }
`;

const Burger = styled.button`
  background-color: transparent;
  float: left;
  padding: 0.5rem;
  margin: 0;
  border-width: 0;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  position: ${({ isOpen }) => (isOpen ? 'fixed' : 'absolute')};
  left: 1rem;
  top: 1rem;
  cursor: pointer;
  z-index: 301;
  ${({ isA11yMode }) => !isA11yMode && 'outline: none;'}

  @media (min-width: ${desktop}) {
    left: 0.75rem;
    top: 1.75rem;
  }
`;

const BurgerIcon = styled.img`
  width: 1.5rem;
`;

const NavLink = styled(Link)`
  color: currentColor;
  text-decoration: none;
  font-size: 3rem;
  display: inline-block;
  overflow: hidden;

  @media (min-width: ${tablet}) {
    font-size: 3.5rem;
    line-height: 1.25;
  }

  @media (min-width: ${desktop}) {
    font-size: 4rem;
  }

  &:hover {
    text-decoration: underline;
  }
`;

const Nav = styled.nav`
  max-width: calc(100% - 4rem);
  width: 18rem;
  background: ${red};
  color: ${pink};
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  z-index: 300;
  padding: 8rem 2rem;
  visibility: hidden;

  ${({ isOpen }) =>
    isOpen ? `visibility: visible;` : `transform: translateX(-100%);`}

  transition: all 0.4s;
  transition-timing-function: ease-in-out;

  @media (min-width: ${tablet}) {
    width: 20rem;
    max-width: none;
    padding: 12rem 4rem;
  }

  @media (min-width: ${desktop}) {
    width: 23rem;
    max-width: none;
    padding: 12rem 4rem;
  }
`;

const Navigation = ({ isOpen, setIsOpen }) => {
  Navigation.handleClickOutside = () => setIsOpen(false);

  const data = useStaticQuery(graphql`
    {
      prismic {
        category_list(uid: "categories", lang: "en-gb") {
          categories {
            category {
              ... on PRISMIC_Category {
                title
                _meta {
                  uid
                }
              }
            }
          }
        }
      }
    }
  `);

  const workUid =
    data?.prismic?.category_list?.categories?.[0]?.category?._meta?.uid;

  return (
    <Nav isOpen={isOpen}>
      <ul>
        <li>
          <NavLink to="/">Home</NavLink>
        </li>
        <li>
          <NavLink to={`/category/${workUid}`}>Work</NavLink>
        </li>
        <li>
          <NavLink to="/services">Services</NavLink>
        </li>
        <li>
          <NavLink to="/about">About Us</NavLink>
        </li>
      </ul>
      <ContactForm nav />
    </Nav>
  );
};

Navigation.prototype = {};

const clickOutsideConfig = {
  handleClickOutside: () => Navigation.handleClickOutside,
};

const NavigationWithClickHandling = onClickOutside(
  Navigation,
  clickOutsideConfig
);

const Heading = styled.h1`
  display: block;
  font-weight: 300;
  font-size: 1rem;
  margin-top: 1rem;
  color: ${green};
  text-align: center;

  @media (min-width: ${desktop}) {
    font-size: 1.5rem;
    margin-top: 1.5rem;
  }
`;

const Header = () => {
  const isA11yMode = useContext(A11yModeContext);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <HeaderComponent>
      <Burger
        isOpen={isOpen}
        isA11yMode={isA11yMode}
        onClick={() => setIsOpen(!isOpen)}
        className="ignore-react-onclickoutside"
      >
        <BurgerIcon src={isOpen ? cross : burger} />
      </Burger>
      <NavigationWithClickHandling isOpen={isOpen} setIsOpen={setIsOpen} />
      <HeaderLink to="/">
        <Logo src={logo} alt="DOCCO" />
      </HeaderLink>
      <Heading>The Doc Collective</Heading>
    </HeaderComponent>
  );
};

export default Header;
