import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import { RichText } from 'prismic-reactjs';
import logo from './icons/logo-red.svg';
import facebook from './icons/facebook.svg';
import instagram from './icons/instagram.svg';
import linkedin from './icons/linkedin.svg';
import twitter from './icons/twitter.svg';
import vimeo from './icons/vimeo.svg';
import youtube from './icons/youtube.svg';
import { yellow, red, tablet, desktop } from '../constants';

const socials = { facebook, instagram, linkedin, twitter, vimeo, youtube };

const FooterComponent = styled.footer`
  color: ${red};
  background: ${yellow};
  padding: 2.5rem;
  font-weight: 300;
  display: flex;
  flex-direction: column;
  margin-top: auto;
`;

const Logo = styled.img`
  width: 100%;
  max-width: 10rem;

  @media (min-width: ${desktop}) {
    width: 10rem;
  }
`;

const SocialIcons = styled.div`
  margin-bottom: 2rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  @media (min-width: ${tablet}) {
    margin-bottom: 0;
  }
`;

const SocialLink = styled.a`
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  width: 2rem;
  height: 2rem;

  &:last-child {
    margin-right: 0;
  }

  @media (min-width: ${tablet}) {
    margin-bottom: 0;
  }
`;

const SocialIcon = styled.img`
  opacity: 0.2;

  &:hover {
    opacity: 1;
  }
`;

const Links = styled.div`
  @media (min-width: ${tablet}) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

const Email = styled.a`
  text-decoration: none;
  font-weight: 300;
  color: ${red};
  margin-top: 1rem;
  display: block;
  font-size: 1.25rem;

  &:hover {
    text-decoration: underline;
  }

  @media (min-width: ${tablet}) {
    margin-top: 0;
  }
`;
const Footer = () => {
  const data = useStaticQuery(graphql`
    {
      prismic {
        footer(uid: "footer", lang: "en-gb") {
          social_links {
            platform_name
            url {
              _linkType
              ... on PRISMIC__ExternalLink {
                url
              }
            }
          }
        }
      }
    }
  `);

  const { social_links } = data.prismic.footer;

  return (
    <FooterComponent>
      <Links>
        <SocialIcons>
          {social_links.map(({ platform_name, url }) => (
            <SocialLink href={url?.url} key={platform_name}>
              <SocialIcon
                alt={platform_name}
                src={socials[platform_name.toLowerCase()]}
              />
            </SocialLink>
          ))}
        </SocialIcons>
        <Logo src={logo} alt="DOCCO" />
        <Email href="mailto:info@docco.co.uk">info@docco.co.uk</Email>
      </Links>
    </FooterComponent>
  );
};

export default Footer;
